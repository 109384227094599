<template>
  <div class="row">

    <div class="col-md-12">
      <b-card>
        <div
          v-for="setting in settings"
          :key="setting.key"
          class="row mb-1"
        >
          <div class="col-md-2">
            <label
              :for="setting.key"
              class="text-capitalize"
            >{{ setting.title }}:</label>
          </div>
          <div class="col-md-10">
            <div v-if="setting.type == 'file'">
              <input
                v-if="checkPermission('update settings')"
                :id="setting.key"
                :ref="setting.key"
                type="file"
                @change="changeFileSetting"
              >
              <br>
              <a
                v-if="setting.value"
                :href="setting.value"
                target="_blank"
              >
                See current file
                <feather-icon
                  icon="ExternalLinkIcon"
                  class="mr-75"
                />
              </a>
            </div>
            <div v-else-if="setting.type == 'boolean'">
              <div class="custom-control custom-checkbox">
                <input
                  :id="setting.key"
                  v-model="formPayload[setting.key]"
                  class="custom-control-input"
                  type="checkbox"
                  :name="setting.key"
                >
                <label
                  class="custom-control-label"
                  :for="setting.key"
                >
                  Yes
                </label>
              </div>
            </div>
            <div
              v-else
              class="input-group"
            >
              <div
                v-if="setting.attributes.prepend != undefined"
                class="input-group-prepend"
              >
                <span class="input-group-text">{{ setting.attributes.prepend }}</span>
              </div>
              <input
                :id="setting.key"
                v-model="formPayload[setting.key]"
                :type="setting.type"
                class="form-control"
                :placeholder="'Enter ' + setting.title + '...'"
                :readonly="!checkPermission('update settings')"
              >
              <div
                v-if="setting.attributes.append != undefined"
                class="input-group-append"
              >
                <span class="input-group-text">{{ setting.attributes.append }}</span>
              </div>
            </div>

            <small>{{ setting.description }}</small>
          </div>
        </div>
        <div
          v-if="checkPermission('update settings')"
          class="row mb-1"
        >
          <div class="col-md-2" />
          <div class="col-md-10">
            <button
              class="btn btn-primary"
              @click="updateSetting()"
            >
              Save Setting
            </button>
          </div>
        </div>
      </b-card>
    </div>

  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  title() {
    return `Setting`
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      settings: [],
      formPayload: {},
    }
  },
  mounted() {
    this.getSetting()
  },
  methods: {
    changeFileSetting(event) {
      this.formPayload[event.target.id] = event.target.files[0]
    },
    getSetting() {
      this.$http.get('/admin/settings')
        .then(response => {
          this.settings = response.data.data
          const context = this
          this.settings.forEach(item => {
            context.formPayload[item.key] = item.value
          })
        })
    },
    updateSetting() {
      const form = new FormData()

      // Remove payload for type = files if not update
      const context = this
      this.settings.forEach(item => {
        if (item.type === 'file' && typeof context.formPayload[item.key] === 'string') {
          context.formPayload[item.key] = null
        }

        if (item.type === 'boolean') {
          context.formPayload[item.key] = context.formPayload[item.key] ? '1' : '0'
        }
      })

      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null && this.formPayload[key] != '') {
          form.append(key, this.formPayload[key])
        }
      }

      form.append('_method', 'PATCH')
      this.$http.post('/admin/settings/', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.getSetting()
          successNotification(this, 'Success', 'Setting updated!')
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
  },
}
</script>

<style>

</style>
